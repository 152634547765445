import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  List,
  Row,
} from 'reactstrap';
import Loading from '../../../containers/DefaultLayout/Loading';
import logo from '../../../containers/DefaultLayout/airbadge-logo.png';
import accountApi from '../../../ajax/Account/accountApi';
import { appSettings } from '../../../App';
import { Requirement } from '../../DefaultWorkflow/Settings/components/ChangePasswordModal';

export default function ResetPasswordPage({ state, location, history, api, register }) {
  const { airport } = state;
  const [newPassword, setNewPassword] = useState('');
  const [newPassword2, setNewPassword2] = useState('');
  const [isDone, setIsDone] = useState(false);
  const [error, setError] = useState(null);
  const [seconds, setSeconds] = useState(null);
  const [fullName, setFullName] = useState('New User');
  const [isEightCharactersLong, setIsEightCharactersLong] = useState(false);
  const [hasOneLowercaseCharacter, setHasOneLowercaseCharacter] = useState(false);
  const [hasOneUppercaseCharacter, setHasOneUppercaseCharacter] = useState(false);
  const [hasOneNumber, setHasOneNumber] = useState(false);

  const goToLogin = () => history.replace('/login');

  const { search } = location;
  if (!search) return goToLogin();

  const params = {};
  search
    .slice(1) // Remove '?' character from the front
    .split('&')
    .forEach(segment => {
      const [key, value] = segment.split('=');
      params[key] = decodeURI(value);
    });

  if (!params.code || !params.uuid) return goToLogin();

  const isNewPasswordsMatching = () => {
    if (!newPassword || !newPassword2) return false;
    return newPassword !== newPassword2;
  };

  const onChangeClick = async () => {
    try {
      setError(null);
      api.toggleLoading(true);
      const { success, error } = await accountApi.resetPassword({
        password: newPassword,
        ...params,
      });

      if (success) {
        setIsDone(true);
        setSeconds(5);
        return;
      }

      switch (error) {
        case 'invalid_request': {
          setError('Invalid request. Please use the exact link sent in your email.');
          break;
        }
        case 'expired_code': {
          setError('Email link has expired. Please request a new password reset from the Login page.');
          break;
        }
        default: {
          setError(error);
        }
      }
    } catch (exception) {
      setError('Unable to reset your password. Please try again and contact the Badge Office if the issue persists.');
      setIsDone(false);
    } finally {
      api.toggleLoading(false);
    }
  };

  const isSaveButtonDisabled = () => {
    if (!newPassword || !newPassword2) return true;
    if (newPassword !== newPassword2) return true;
    if (!isEightCharactersLong) return true;
    if (!hasOneLowercaseCharacter) return true;
    if (!hasOneUppercaseCharacter) return true;
    return !hasOneNumber;
  };

  const evaluatePasswordStrength = () => {
    setIsEightCharactersLong(newPassword.length >= 8);
    setHasOneLowercaseCharacter(newPassword.match(/[a-z]/));
    setHasOneUppercaseCharacter(newPassword.match(/[A-Z]/));
    setHasOneNumber(newPassword.match(/[0-9]/));
  };

  useEffect(() => {
    evaluatePasswordStrength(newPassword);
  }, [newPassword]);

  useEffect(() => {
    if (seconds === null) return;
    if (seconds === 0) return goToLogin();
    setTimeout(() => setSeconds(seconds - 1), 1000);
  }, [seconds]);

  useEffect(() => {
    if (params.uuid) {
      accountApi
        .getUserFullName({ userUuid: params.uuid })
        .then(({ success, fullName }) => {
          if (success) setFullName(fullName);
        })
        .catch(e => console.error(e));
    }
  }, []);

  return (
    <div className="app flex-row align-items-center">
      <Loading loading={state.loading} />
      <Container>
        <Row className="justify-content-center">
          <Col sm="12" md="10" lg="8" xl="6">
            <div className="text-center py-4">
              <img src={logo} style={appSettings.bigLogoStyle} alt="AirBadge" />
            </div>
            <Card>
              <CardBody>
                {isDone && (
                  <div>
                    <div style={{ marginBottom: 10 }}>Your password was successfully reset</div>
                    <div style={{ marginBottom: 25 }}>
                      You will be redirected to the Login page in {seconds} seconds ...
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      <Button onClick={goToLogin}>Go to Login</Button>
                    </div>
                  </div>
                )}
                {!isDone && (
                  <>
                    <div style={{ textAlign: 'center' }}>
                      <h1 className="mt-2 mb-3">{register ? `Welcome, ${fullName}` : 'Reset Password'}</h1>
                      {register && (
                        <div className="text-center text-muted mb-4">
                          Before you can use AirBadge, you will need to set your password now
                        </div>
                      )}
                    </div>

                    {error && <Alert color="danger">{error}</Alert>}

                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>{register ? 'Password' : 'New Password'}</Label>
                          <Input
                            type="password"
                            value={newPassword}
                            onChange={({ target }) => setNewPassword(target.value)}
                            required
                          />
                          <FormFeedback>New password must be different than your current password</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>{register ? 'Re-enter Password' : 'Re-enter New Password'}</Label>
                          <Input
                            type="password"
                            value={newPassword2}
                            onChange={({ target }) => setNewPassword2(target.value)}
                            required
                            invalid={isNewPasswordsMatching()}
                          />
                          <FormFeedback>Passwords must match</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>

                    <strong>Password Requirements</strong>
                    <List style={{ marginTop: 8 }}>
                      <Requirement isGood={isEightCharactersLong} text="At least 8 characters long" />
                      <Requirement isGood={hasOneLowercaseCharacter} text="Contains at least 1 lower case character" />
                      <Requirement isGood={hasOneUppercaseCharacter} text="Contains at least 1 upper case character" />
                      <Requirement isGood={hasOneNumber} text="Contains at least 1 number" />
                    </List>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginTop: 40,
                      }}
                    >
                      <Button
                        style={{ width: 200 }}
                        disabled={isSaveButtonDisabled()}
                        color="primary"
                        onClick={onChangeClick}
                      >
                        {register ? 'Set Password' : 'Reset Password'}
                      </Button>
                      <button type="button" className="btn btn-link" onClick={goToLogin} style={{ marginTop: 24 }}>
                        Return to Login
                      </button>
                    </div>
                  </>
                )}
              </CardBody>
            </Card>
            <div className="text-center mt-4">
              <p>AirBadge is Provided by</p>
              <p>{airport && airport.name}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
