import React, { createContext, useContext, useEffect, useState } from 'react';
import get from 'lodash/get';
import { Alert, Card, CardBody, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import moment from 'moment-timezone';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EmailIcon from '@material-ui/icons/MailOutline';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { AppContext, colors } from '../../../../App';
import peopleApi from '../../../../ajax/People/peopleApi';
import settingsApi from '../../../../ajax/Settings/settingsApi';
import CustomModalHeader from '../../../../shared/components/CustomModalHeader/CustomModalHeader';
import ModalActions from '../../../../shared/components/ModalActions/ModalActions';
import ReadOnlyFormData from '../../../../shared/components/ReadOnlyFormData/ReadOnlyFormData';
import FontAwesomeIcon from '../../../../shared/components/FontAwesome/FontAwesomeIcon';
import Collapsible from '../../../../shared/components/Collapsible/Collapsible';
import { formatPhoneNumber, handleError, notify, sanitizePhoneNumber } from '../../../../utils';
import PeopleEditSimpleFieldModal from './components/PeopleEditSimpleFieldModal';
import PersonEditTscAssociationModal from './components/PersonEditTscAssociationModal';
import MessagesCreateModal from '../../Messages/MessagesCreateModal';
import PersonEditFiles from './components/PersonEditFiles';
import PersonEditComments from './components/PersonEditComments';
import PersonEditEnrollMobileModal from './components/PersonEditEnrollMobileModal';
import Feature, { hasFeature } from '../../../../shared/Feature';
import PersonAccomplishments from './components/PersonAccomplishments';
import PersonManageTrainingModal from './Training/PersonManageTrainingModal';
import PeopleEditTscExemptionModal from './components/PeopleEditTscExemptionModal';
import PersonEditBadges from './components/PersonEditBadges';
import UpdateFullNameModal from './components/UpdateFullNameModal';
import UpdateDateOfBirthModal from './components/UpdateDateOfBirthModal';
import PersonEditBadgeApplications from './components/PersonEditBadgeApplications';
import PersonDetailsRolesList from './components/PersonDetailsRolesList';
import PersonEditModalTscInfo from './components/PersonEditModalTscInfo';
import GenerateKioskCodeForUserModal from './components/GenerateKioskCodeForUserModal';
import BannedBanner from '../../../../shared/BannedBanner/BannedBanner';
import BanAccountAction from './components/BanAccountAction';
import MergeDuplicateUsersModal from './MergeDuplicateUsersModal';
import ViolationsLimitedListing from '../../Violations/ViolationsLimitedListing';
import AdminPullFullTscInfo from './AdminPullFullTscInfo';
import BadgeApplicationTrainingConfirmation from './components/BadgeApplicationTrainingConfirmation';
import ViewTSCInfoAction from './components/ViewTSCInfoAction';
import PersonEditAppointments from './components/PersonEditAppointments';
import FullUserActivity from '../../../../shared/FullUserActivity/FullUserActivity';
import PersonLoginHistoryModal from './PersonLoginHistoryModal';
import PersonInfoModalHeader from './components/PersonInfoModalHeader';
import WebcamCaptureModal from '../../Badges/BadgesList/WebcamCaptureModal';
import { uploadProfilePicForPerson } from '../../../../imageUtils';
import PersonEditKeys from './components/PersonEditKeys';
import request from '../../../../ajax/Request';

export const FULL_DATE_FORMAT = 'MMM DD, YYYY';

export const PersonModalContext = createContext();

export function buildFullName({ first_name, middle_name, last_name, suffix, nickname }) {
  let name = '';
  if (first_name) {
    name = `${first_name.toLocaleUpperCase()}`;
  }
  if (middle_name) {
    name = `${name} ${middle_name.toLocaleUpperCase()}`;
  }
  if (last_name) {
    name = `${name} ${last_name.toLocaleUpperCase()}`;
  }
  if (suffix) {
    name = `${name}, ${suffix.toLocaleUpperCase()} `;
  }
  if (nickname) {
    name = `${name} (${nickname})`;
  }
  return name;
}

export function BadgesLabel({ numActiveBadges, isShortVersion = false }) {
  const style = { fontSize: 'smaller', fontStyle: 'italic' };

  let countLabel = `${numActiveBadges} active `;
  if (numActiveBadges > 1) {
    countLabel += 'badges';
  } else {
    countLabel += 'badge';
  }

  if (isShortVersion) {
    let extra;
    if (!numActiveBadges) {
      extra = <span style={style}>(no active badge)</span>;
    } else {
      extra = <span style={style}>({countLabel})</span>;
    }
    return <>Badges {extra}</>;
  }

  return <div style={style}>({!numActiveBadges ? 'no active badge' : countLabel})</div>;
}

export default function PeopleListEditModal({ isOpen, onClose, selectedPerson }) {
  const app = useContext(AppContext);
  const [person, setPerson] = useState(null);
  const [fullName, setFullName] = useState('-');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSimpleEditModalOpen, setIsSimpleEditModalOpen] = useState(false);
  const [editConfig, setEditConfig] = useState({});
  const [isInfoExpanded, setIsInfoExpanded] = useState(false);
  const [isCommentsExpanded, setIsCommentsExpanded] = useState(false);
  const [isFilesExpanded, setIsFilesExpanded] = useState(false);
  const [isTscEditModalOpen, setIsTscEditModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isEnrollModalOpen, setIsEnrollModalOpen] = useState(false);
  const [isManageTrainingModalOpen, setIsManageTrainingModalOpen] = useState(false);
  const [isAccomplishmentsExpanded, setIsAccomplishmentsExpanded] = useState(false);
  const [isBadgesExpanded, setIsBadgesExpanded] = useState(false);
  const [isRolesExpanded, setIsRolesExpanded] = useState(false);
  const [isTscInfoExpanded, setIsTscInfoExpanded] = useState(false);
  const [isBadgeApplicationsExpanded, setIsBadgeApplicationsExpanded] = useState(false);
  const [isExemptModalOpen, setIsExemptModalOpen] = useState(false);
  const [isTrainingEnabled, setIsTrainingEnabled] = useState(false);
  const isSignatory = get(app, 'user.activeProfile.role.name', '') === 'authorized-signatory';
  const [fNameModalOpen, setFNameModalOpen] = useState(false);
  const [dobModalOpen, setDobModalOpen] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState('-');
  const [invitationEmailLabel, setInvitationEmailLabel] = useState('Send AirBadge Invitation Email');
  const [roles, setRoles] = useState([]);
  const [rapbackCancellationWarning, setRapbackCancellationWarning] = useState(null);
  const [isDuplicatesModalOpen, setIsDuplicatesModalOpen] = useState(false);
  const [duplicates, setDuplicates] = useState([]);
  const [isViolationsExpanded, setIsViolationsExpanded] = useState(false);
  const [isAdminPullFullTscInfoOpen, setIsAdminPullFullTscInfoOpen] = useState(false);
  const [isBadgeApplicationTrainingConfirmationOpen, setIsBadgeApplicationTrainingConfirmationOpen] = useState(false);
  const [isAppointmentsExpanded, setIsAppointmentsExpanded] = useState(false);
  const [isFullUserActivityOpen, setIsFullUserActivityOpen] = useState(false);
  const [isPersonLoginHistoryModalOpen, setIsPersonLoginHistoryModalOpen] = useState(false);
  const [isHeadshotsEnabled, setIsHeadshotsEnabled] = useState(false);
  const [isWebcamCaptureModalOpen, setIsWebcamCaptureModalOpen] = useState(false);
  const [isKeysExpanded, setIsKeysExpanded] = useState(false);

  const onModalClose = () => {
    setRoles([]);
    setPerson(null);
    setIsModalOpen(false);
    setIsInfoExpanded(false);
    setIsCommentsExpanded(false);
    setIsFilesExpanded(false);
    setIsBadgesExpanded(false);
    setIsRolesExpanded(false);
    setIsTscInfoExpanded(false);
    setIsAccomplishmentsExpanded(false);
    setIsBadgeApplicationsExpanded(false);
    setRapbackCancellationWarning(null);
    setIsViolationsExpanded(false);
    setIsAppointmentsExpanded(false);
    setIsFullUserActivityOpen(false);
    setIsPersonLoginHistoryModalOpen(false);
    setIsKeysExpanded(false);
    onClose();
  };

  const onWebcamCaptureModalClose = async args => {
    setIsWebcamCaptureModalOpen(false);

    if (!args?.imageData) return;

    app.api.toggleLoading(true);
    uploadProfilePicForPerson({ userUuid: person.uuid, imageData: args.imageData })
      .then(() => loadData())
      .catch(error => handleError({ error }))
      .finally(() => app.api.toggleLoading(false));
  };

  const onViewTSCInfoActionDone = ({ shouldReload }) => {
    if (shouldReload) loadData();
  };

  const onBanAccountActionDone = ({ shouldReload }) => {
    if (shouldReload) loadData();
  };

  const evaluateRapbackCancellationWarning = person => {
    if (
      person?.tsc_rapback_status === 'Subscribed' &&
      person?.tsc_rapback_should_cancel_on &&
      !person?.tsc_rapback_cancel_initiated_on
    ) {
      const cancelOnDate = moment.tz(person?.tsc_rapback_should_cancel_on, app.timezone).format('MMMM DD, YYYY');
      setRapbackCancellationWarning(`TSC Rapback Subscription will be cancelled on ${cancelOnDate}`);
    }
  };

  const loadData = () => {
    const { api } = app;
    const { uuid } = selectedPerson;
    api.toggleLoading(true);
    peopleApi
      .getPerson({ uuid })
      .then(({ success, person, activeProfiles }) => {
        if (success) {
          setRoles(activeProfiles || []);
          setFullName(buildFullName(person));
          setDateOfBirth(person.date_of_birth ? moment(person.date_of_birth).format(FULL_DATE_FORMAT) : '-');
          setPerson({ ...person, dob: person.dob || person.date_of_birth });
          if (person.last_login !== null) {
            setInvitationEmailLabel('Resend AirBadge Invitation Email');
          } else {
            setInvitationEmailLabel('Send AirBadge Invitation Email');
          }
          evaluateRapbackCancellationWarning(person);
        }
      })
      .catch(error => handleError({ error }))
      .finally(() => api.toggleLoading(false));
  };

  const onDisenrollClicked = () => {
    const { api, user } = app;
    api.confirmMessage(
      'Confirmation',
      `Are you sure you want to remove mobile notifications enrollment for this person?`,
      () => {
        api.toggleLoading(true);
        settingsApi
          .removeMobileEnrollmentForUser({ user, userUUID: person.uuid })
          .then(({ success, message }) => {
            if (!success) {
              return handleError({ message: message || 'Unable to remove mobile notifications enrollment' });
            }

            // If the current user is editing themselves, we need to update their
            // cached user data so they don't have to log out and back in to refresh the data
            if (user.uuid === person.uuid) {
              const updatedUser = { ...user, mobile: null };
              api.updateState({ user: updatedUser });
              localStorage.setItem('AirportBadges.user', JSON.stringify(updatedUser));
            }

            const updatedPerson = { ...person };
            updatedPerson.mobile = null;
            setPerson(updatedPerson);
          })
          .catch(error => handleError({ error, message: 'Unable to remove mobile notifications enrollment' }))
          .finally(() => api.toggleLoading(false));
      }
    );
  };

  const onEnrollClicked = () => {
    setIsEnrollModalOpen(true);
  };

  const onManageTrainingClicked = () => {
    if (person.hasActiveBadgeApplication) {
      setIsBadgeApplicationTrainingConfirmationOpen(true);
    } else {
      setIsManageTrainingModalOpen(true);
    }
  };

  const onEnrollSave = ({ newValue }) => {
    const { api } = app;
    api.toggleLoading(true);
    const sanitized = sanitizePhoneNumber(newValue);
    peopleApi
      .patch({ uuid: person.uuid, updates: { mobile: sanitized, comment: '' } })
      .then(({ success, message }) => {
        if (success) {
          const updatedPerson = { ...person };
          updatedPerson.mobile = sanitized;
          setPerson(updatedPerson);
          setIsEnrollModalOpen(false);
        } else {
          handleError({ message: message || 'Unable to update this User' });
        }
      })
      .catch(error => handleError({ error, message: 'Unable to update this individual' }))
      .finally(() => api.toggleLoading(false));
  };

  const onEnrollClose = () => {
    setIsEnrollModalOpen(false);
  };

  const onManageTrainingClose = () => {
    setIsManageTrainingModalOpen(false);
  };

  const onClickResendInvitation = () => {
    const { user } = app;
    peopleApi
      .resendInvitation({ user, uuid: person.uuid })
      .then(({ success, message }) => {
        if (success) {
          notify({ message: `Invitation email sent to ${fullName}`, severity: 'success' });
        } else {
          handleError({ message: message || 'Unable to resend invitation' });
        }
      })
      .catch(error => handleError({ error, message: 'Unable to resend invitation' }));
  };

  const onSimpleEditModalClose = () => {
    setEditConfig({});
    setIsSimpleEditModalOpen(false);
  };

  const onSimpleEditModalSave = ({ newValue, comment }) => {
    const { api } = app;
    api.toggleLoading(true);
    const updates = {};
    updates[editConfig.property] = newValue;
    peopleApi
      .patch({ uuid: person.uuid, updates, comment })
      .then(({ success, message }) => {
        if (success) {
          setEditConfig({});
          setIsSimpleEditModalOpen(false);

          const updatedPerson = { ...person };
          updatedPerson[editConfig.property] = newValue;
          setPerson(updatedPerson);
        } else {
          handleError({ message: message || 'Unable to update this User' });
        }
      })
      .catch(error => handleError({ error, message: 'Unable to update this User' }))
      .finally(() => api.toggleLoading(false));
  };

  const onChangeNicknameClicked = () => {
    setEditConfig({ type: 'text', label: 'Nickname', value: person.nickname, property: 'nickname' });
    setIsSimpleEditModalOpen(true);
  };

  const onChangePhonesClicked = () => {
    setEditConfig({ type: 'textarea', label: 'Phones (other)', value: person.phone, property: 'phone' });
    setIsSimpleEditModalOpen(true);
  };

  const onChangeEmployeeIdClicked = () => {
    setEditConfig({ type: 'text', label: "Employer's ID", value: person.employee_id, property: 'employee_id' });
    setIsSimpleEditModalOpen(true);
  };

  const onTscEditModalClose = () => {
    setIsTscEditModalOpen(false);
  };

  const onTscEditModalSave = ({ newValue }) => {
    const { api } = app;
    api.toggleLoading(true);
    peopleApi
      .patch({ uuid: person.uuid, updates: { tsc_person_id: newValue, comment: '' } })
      .then(({ success, message }) => {
        if (success) {
          const updatedPerson = { ...person };
          updatedPerson.tsc_person_id = newValue;
          setPerson(updatedPerson);
          setIsTscEditModalOpen(false);
        } else {
          handleError({ message: message || 'Unable to update this User' });
        }
      })
      .catch(error => handleError({ error, message: 'Unable to update this User' }))
      .finally(() => api.toggleLoading(false));
  };

  const onChangeDocsExpiresClicked = () => {
    setEditConfig({
      type: 'date',
      label: 'Identification Documents Expire On',
      value: person.docs_expire,
      property: 'docs_expire',
    });
    setIsSimpleEditModalOpen(true);
  };

  const onDeactivateAccountClicked = () => {
    const { api } = app;
    const { uuid } = person;
    api.confirmMessage(
      'Deactivate Account',
      <div>
        <p>WARNING!</p>
        <p>This will disable the person from logging into AirBadge. No data will be deleted.</p>
        <p>
          Are you sure you want to deactivate the account for <strong>{fullName}</strong>?
        </p>
      </div>,
      () => {
        api.toggleLoading(true);
        peopleApi
          .deactivate({ uuid })
          .then(({ success, message }) => {
            if (!success) {
              return handleError({ message: message || `Unable to deactivate account for ${fullName}` });
            }
            const updatedPerson = { ...person };
            updatedPerson.active = false;
            setPerson(updatedPerson);
          })
          .catch(error => handleError({ error, message: `Unable to deactivate account for ${fullName}` }))
          .finally(() => api.toggleLoading(false));
      },
      null,
      true
    );
  };

  const onActivateAccountClicked = () => {
    const { api, user } = app;
    const { uuid } = person;
    api.toggleLoading(true);
    peopleApi
      .activate({ user, uuid })
      .then(({ success }) => {
        if (success) {
          const updatedPerson = { ...person };
          updatedPerson.active = true;
          setPerson(updatedPerson);
        }
      })
      .catch(error => handleError({ error, message: `Unable to deactivate account for ${fullName}` }))
      .finally(() => api.toggleLoading(false));
  };

  const onChangeEmailClicked = () => {
    setEditConfig({
      type: 'text',
      label: 'Email Address',
      value: person.email,
      property: 'email',
      extraText: `${fullName} will receive a message at the old and new email addresses informing them of the change.`,
    });
    setIsSimpleEditModalOpen(true);
  };

  const onMessageModalClose = () => {
    setIsMessageModalOpen(false);
  };

  const onUnlockAccountClicked = () => {
    const { uuid } = person;
    app.api.toggleLoading(true);
    request('authenticated-user', 'POST', `account/${uuid}/unlock-account`)
      .then(() => {
        notify({ message: 'Account has been unlocked and the login attempts have been reset' });
        loadData();
      })
      .catch(error => handleError({ error }))
      .finally(() => app.api.toggleLoading(false));
  };

  const onSendMessageClicked = () => {
    setIsMessageModalOpen(true);
  };

  const onMarkTscExempt = () => {
    setIsExemptModalOpen(true);
  };

  const onExemptClose = () => {
    setIsExemptModalOpen(false);
  };

  const onExemptSave = () => {
    setIsExemptModalOpen(false);
    setPerson({ ...person, is_tsc_exempt: person.is_tsc_exempt ? 0 : 1 });
  };

  const onEditDateOfBirth = () => {
    setDobModalOpen(true);
  };

  const isFieldEditable = () => {
    if (!person) {
      return false;
    }

    if (person.is_tsc_exempt) {
      return true;
    }

    return !(person.tsc_sta_id || person.tsc_chrc_case_number);
  };

  const onFindDuplicates = () => {
    // Check for possible duplicates!
    app.api.toggleLoading(true);
    peopleApi
      .findDuplicates({
        firstName: person.first_name,
        middleName: person.middle_name,
        lastName: person.last_name,
        dateOfBirth: person.date_of_birth,
        excludedUserUuid: person.uuid,
      })
      .then(({ success, results }) => {
        if (success && results?.length) {
          // STOP!
          app.api.toggleLoading(false);
          setDuplicates(results);
          setIsDuplicatesModalOpen(true);
        } else {
          notify({ message: 'No duplicate records found' });
        }
      })
      .catch(error => {
        handleError({ error });
      })
      .finally(() => app.api.toggleLoading(false));
  };

  const onDuplicatesModalClosed = ({ wasMerged }) => {
    setIsDuplicatesModalOpen(false);
    if (wasMerged) {
      loadData();
    }
  };

  const onDissociateClicked = () => {
    app.api.toggleLoading(true);
    peopleApi
      .patch({ uuid: person.uuid, updates: { pacs_person_id: null } })
      .then(({ success }) => {
        if (success) {
          notify({ message: 'PACS ID removed from this person and their badges' });
          loadData();
        } else {
          app.api.toggleLoading(false);
          handleError({ message: 'Failed' });
        }
      })
      .catch(error => {
        app.api.toggleLoading(false);
        handleError({
          error,
          message: 'Failed',
        });
      });
  };

  useEffect(() => {
    if (!isOpen || !selectedPerson) return;

    loadData();
    setIsModalOpen(true);
  }, [isOpen, selectedPerson]);

  useEffect(() => {
    if (!app.airport) return;

    const { isIETEnabled, isDigicastEnabled, isAirBadgeLMSEnabled, isSSIEnabled, config } = app.airport;
    setIsTrainingEnabled(isIETEnabled || isDigicastEnabled || isAirBadgeLMSEnabled || isSSIEnabled);

    const isHeadshotsFeatureEnabled = hasFeature('headshot_pictures', config?.features || []);
    setIsHeadshotsEnabled(isHeadshotsFeatureEnabled);
  }, [app.airport]);

  if (!isModalOpen || !person) return null;

  return (
    <Modal size="lg" isOpen={isModalOpen} toggle={onModalClose}>
      <CustomModalHeader toggle={onModalClose}>
        Person Details {!person.active ? <span className="text-danger">(deactivated)</span> : ''}
      </CustomModalHeader>
      <ModalBody>
        <BannedBanner user={person} />

        {!!rapbackCancellationWarning && <Alert color="warning">{rapbackCancellationWarning}</Alert>}

        {person.login_attempts >= person.maxAllowableLoginAttempts && (
          <Alert fade={false} color="warning">
            This account has been locked due to too many login attempts. Click the Unlock Account action below to reset
            the login attempts, and allow this person to reset their password or attempt to login again.
          </Alert>
        )}

        <PersonInfoModalHeader person={person} fullName={fullName} dateOfBirth={dateOfBirth} />

        <div className="separator" style={{ marginTop: 32 }}>
          ACTIONS
        </div>

        <Row className="mb-4">
          <Col>
            <List>
              {person.login_attempts >= person.maxAllowableLoginAttempts && (
                <ListItem button onClick={onUnlockAccountClicked} style={{ backgroundColor: 'rgb(255, 242.6, 205.4)' }}>
                  <ListItemIcon>
                    <LockOpenIcon style={{ color: colors.primary }} />
                  </ListItemIcon>
                  <ListItemText primary="Unlock Account" />
                </ListItem>
              )}

              <Feature id="kiosk">
                <GenerateKioskCodeForUserModal user={person} />
              </Feature>
              <Feature id="messages">
                {person.active ? (
                  <ListItem button onClick={onSendMessageClicked}>
                    <ListItemIcon>
                      <FontAwesomeIcon
                        className="fa fa-paper-plane-o"
                        color={colors.primary}
                        style={{ fontSize: '1.4rem', paddingLeft: 3 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Send Message" />
                  </ListItem>
                ) : null}
              </Feature>
              {!isSignatory && (
                <>
                  <ListItem button onClick={onChangeEmailClicked}>
                    <ListItemIcon classes={{ root: 'width-adjust' }}>
                      <EmailIcon style={{ color: colors.warning }} />
                    </ListItemIcon>
                    <ListItemText primary="Change Email" />
                  </ListItem>
                </>
              )}
              <>
                {person.active ? (
                  <ListItem button onClick={onDeactivateAccountClicked}>
                    <ListItemIcon>
                      <LockIcon style={{ color: colors.danger }} />
                    </ListItemIcon>
                    <ListItemText primary="Disable Login" />
                  </ListItem>
                ) : (
                  <ListItem button onClick={onActivateAccountClicked}>
                    <ListItemIcon>
                      <LockOpenIcon style={{ color: colors.primary }} />
                    </ListItemIcon>
                    <ListItemText primary="Enable Login" />
                  </ListItem>
                )}
              </>
              {isTrainingEnabled && (
                <ListItem button onClick={onManageTrainingClicked}>
                  <ListItemIcon>
                    <FontAwesomeIcon className="fa-solid fa-school" style={{ fontSize: 22 }} />
                  </ListItemIcon>
                  <ListItemText primary="Manage Training Assignments" />
                </ListItem>
              )}
              {isHeadshotsEnabled && (
                <ListItem button onClick={() => setIsWebcamCaptureModalOpen(true)}>
                  <ListItemIcon>
                    <FontAwesomeIcon className="fa-solid fa-camera" style={{ fontSize: 22 }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={person.headshot_picture ? 'Replace Headshot Picture' : 'Provide Headshot Picture'}
                  />
                </ListItem>
              )}
            </List>
          </Col>
          <Col>
            <List>
              {!isSignatory && (
                <>
                  <ListItem button onClick={onMarkTscExempt}>
                    <ListItemIcon>
                      <FontAwesomeIcon
                        className={person.is_tsc_exempt ? 'fa fa-exchange' : 'fa fa-minus-circle'}
                        color={colors.warning}
                      />
                    </ListItemIcon>
                    <ListItemText primary={person.is_tsc_exempt ? 'Remove STA Exemption' : 'Mark STA Exempt'} />
                  </ListItem>
                  <ListItem button onClick={() => onFindDuplicates()}>
                    <ListItemIcon>
                      <FontAwesomeIcon className="fa-regular fa-clone" color={colors.primary} />
                    </ListItemIcon>
                    <ListItemText primary="Find Duplicate Records" />
                  </ListItem>
                </>
              )}
              {person.mobile && (
                <ListItem button onClick={onDisenrollClicked}>
                  <ListItemIcon>
                    <FontAwesomeIcon className="fa-solid fa-mobile-screen" color={colors.warning} />
                  </ListItemIcon>
                  <ListItemText primary="Disenroll Mobile Notifications" />
                </ListItem>
              )}
              {!person.mobile && (
                <ListItem button onClick={onEnrollClicked}>
                  <ListItemIcon>
                    <FontAwesomeIcon className="fa-solid fa-mobile-screen" color={colors.primary} />
                  </ListItemIcon>
                  <ListItemText primary="Enroll Mobile Notifications" />
                </ListItem>
              )}
              <ListItem button onClick={onClickResendInvitation}>
                <ListItemIcon>
                  <EmailIcon style={{ color: colors.primary }} />
                </ListItemIcon>
                <ListItemText primary={invitationEmailLabel} />
              </ListItem>
              <BanAccountAction
                userUuid={person.uuid}
                currentStatus={person.status}
                userFullName={fullName}
                onDone={onBanAccountActionDone}
              />
              <ViewTSCInfoAction person={person} onDone={onViewTSCInfoActionDone} />
            </List>
          </Col>
        </Row>

        {!!app?.user?.isSuperAdmin && (
          <>
            <div className="separator text-danger">ADMIN ACTIONS</div>
            <style>{`
              .color-adjust {
                color: ${colors.danger}
              }
            `}</style>
            <Row className="mb-3">
              <Col>
                <List>
                  <ListItem
                    button
                    onClick={() => setIsAdminPullFullTscInfoOpen(true)}
                    disabled={!person?.tsc_person_id}
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon
                        className="fa-solid fa-building-shield"
                        color={colors.danger}
                        style={{ fontSize: 18 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Fetch Full TSC Info" classes={{ root: 'color-adjust' }} />
                  </ListItem>
                  <ListItem button onClick={() => setIsFullUserActivityOpen(true)}>
                    <ListItemIcon>
                      <FontAwesomeIcon
                        className="fa-solid fa-comments"
                        color={colors.danger}
                        style={{ fontSize: 18 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Fetch Full Comments/Activity" classes={{ root: 'color-adjust' }} />
                  </ListItem>
                  <ListItem button onClick={() => setIsPersonLoginHistoryModalOpen(true)}>
                    <ListItemIcon>
                      <FontAwesomeIcon
                        className="fa-solid fa-timeline"
                        color={colors.danger}
                        style={{ fontSize: 18 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Fetch Login History" classes={{ root: 'color-adjust' }} />
                  </ListItem>
                </List>
              </Col>
              <Col>
                <List>
                  <ListItem button onClick={onDissociateClicked} disabled={!person?.pacs_person_id}>
                    <ListItemIcon>
                      <FontAwesomeIcon
                        className="fa-solid fa-link-slash"
                        color={colors.danger}
                        style={{ fontSize: 18 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Dissociate From PACS" classes={{ root: 'color-adjust' }} />
                  </ListItem>
                </List>
              </Col>
            </Row>
          </>
        )}

        <Collapsible
          id="people-info"
          title="Details"
          icon={<i className="fa-solid fa-list mr-2" />}
          expanded={isInfoExpanded}
          onChange={() => setIsInfoExpanded(!isInfoExpanded)}
          style={{ backgroundColor: '#f0f0f0' }}
        >
          <Card>
            <CardBody>
              <style>{`
                .spacer {
                  margin-bottom: 32px;
                }
              `}</style>

              <Row className="spacer">
                <Col>
                  <ReadOnlyFormData skipTextTransform label="Airport Person ID" value={person.tsc_person_id} />
                </Col>
              </Row>
              <Row className="spacer">
                <Col>
                  {isFieldEditable() ? (
                    <ReadOnlyFormData
                      skipTextTransform
                      label="Full Name"
                      value={buildFullName({ ...person, nickname: null })}
                      onEdit={() => setFNameModalOpen(true)}
                    />
                  ) : (
                    <ReadOnlyFormData
                      skipTextTransform
                      label="Full Name"
                      value={buildFullName({ ...person, nickname: null })}
                    />
                  )}
                </Col>
                <Col>
                  <ReadOnlyFormData
                    skipTextTransform
                    label="Nickname"
                    value={person.nickname || '-'}
                    onEdit={isSignatory ? null : onChangeNicknameClicked}
                  />
                </Col>
              </Row>

              <Row className="spacer">
                <Col>
                  <ReadOnlyFormData skipTextTransform label="Email" value={person.email || '-'} />
                  <a
                    href={`mailto:${person.email}`}
                    className="btn btn-link"
                    style={{ fontSize: 12, position: 'absolute', top: 32, left: 0 }}
                  >
                    <FontAwesomeIcon className="fa-regular fa-paper-plane" style={{ fontSize: 12, marginRight: 4 }} />
                    Send Email
                  </a>
                </Col>
                <Col>
                  <ReadOnlyFormData
                    skipTextTransform
                    label="Last Login"
                    value={person.last_login ? moment(person.last_login).format('MM/DD/YYYY h:mm a') : '-'}
                  />
                </Col>
              </Row>

              <Row className="spacer">
                <Col>
                  {isFieldEditable() ? (
                    <ReadOnlyFormData
                      skipTextTransform
                      label="Date Of Birth"
                      value={dateOfBirth}
                      onEdit={onEditDateOfBirth}
                    />
                  ) : (
                    <ReadOnlyFormData skipTextTransform label="Date Of Birth" value={dateOfBirth} />
                  )}
                </Col>
                <Col>
                  <ReadOnlyFormData
                    skipTextTransform
                    label="Mobile Phone"
                    value={person.mobile ? formatPhoneNumber(person.mobile) : '-'}
                  />
                </Col>
              </Row>

              <Row className="spacer">
                <Col>
                  <ReadOnlyFormData
                    skipTextTransform
                    label="Phones (other)"
                    value={person.phone || '-'}
                    onEdit={isSignatory ? null : onChangePhonesClicked}
                  />
                </Col>
                <Col>
                  <ReadOnlyFormData
                    skipTextTransform
                    label="First Responder?"
                    value={person.first_responder ? 'Yes' : 'No'}
                  />
                </Col>
              </Row>

              <Row className="spacer">
                {!isSignatory && (
                  <Col>
                    <ReadOnlyFormData
                      skipTextTransform
                      label="Identification Documents Expire On"
                      value={person.docs_expire ? moment(person.docs_expire).format(FULL_DATE_FORMAT) : '-'}
                      onEdit={onChangeDocsExpiresClicked}
                    />
                  </Col>
                )}
                <Col>
                  <ReadOnlyFormData
                    skipTextTransform
                    label="Employer's ID"
                    value={person.employee_id || '-'}
                    onEdit={onChangeEmployeeIdClicked}
                  />
                </Col>
              </Row>

              {app.airport.isPacsEnabled && (
                <Row className="spacer">
                  <Col>
                    <ReadOnlyFormData
                      skipTextTransform
                      label="Physical Access Control System (PACS) ID"
                      value={
                        person.pacs_person_id ? (
                          <div style={{ display: 'inline-block' }}>
                            <FontAwesomeIcon
                              className="fa fa-check"
                              color={colors.success}
                              style={{ fontSize: '1rem', paddingLeft: 0 }}
                            />{' '}
                            Associated
                          </div>
                        ) : (
                          <div style={{ display: 'inline-block' }}>
                            <FontAwesomeIcon
                              className="fa fa-ban"
                              color={colors.danger}
                              style={{ fontSize: '1rem', paddingLeft: 0 }}
                            />{' '}
                            Not associated
                          </div>
                        )
                      }
                    />
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        </Collapsible>

        {!isSignatory && (
          <Collapsible
            id="people-tsc-info"
            title="Vetting Information"
            icon={<i className="fa-solid fa-user-shield mr-2" />}
            expanded={isTscInfoExpanded}
            onChange={() => setIsTscInfoExpanded(!isTscInfoExpanded)}
            style={{ backgroundColor: '#f0f0f0' }}
          >
            <PersonEditModalTscInfo person={person} isOpen={isTscInfoExpanded} />
          </Collapsible>
        )}

        <Collapsible
          id="people-badges"
          title={<BadgesLabel numActiveBadges={person.numActiveBadges} isShortVersion={true} />}
          icon={<i className="fa-regular fa-id-card mr-2" />}
          expanded={isBadgesExpanded}
          onChange={() => setIsBadgesExpanded(!isBadgesExpanded)}
          style={{ backgroundColor: '#f0f0f0' }}
        >
          <PersonEditBadges selectedPerson={person} isOpen={isBadgesExpanded} />
        </Collapsible>

        <Collapsible
          id="people-badge-applications"
          title="Badge Applications"
          icon={<i className="fa fa-file-text-o mr-2" />}
          expanded={isBadgeApplicationsExpanded}
          onChange={() => setIsBadgeApplicationsExpanded(!isBadgeApplicationsExpanded)}
          style={{ backgroundColor: '#f0f0f0' }}
        >
          <PersonEditBadgeApplications selectedPerson={person} />
        </Collapsible>

        <Collapsible
          id="people-appointments"
          title="Appointments"
          icon={<i className="fa-solid fa-calendar-alt mr-2" />}
          expanded={isAppointmentsExpanded}
          onChange={() => setIsAppointmentsExpanded(!isAppointmentsExpanded)}
          style={{ backgroundColor: '#f0f0f0' }}
        >
          <PersonEditAppointments person={person} isOpen={isAppointmentsExpanded} />
        </Collapsible>

        <Collapsible
          id="people-keys"
          title="Keys"
          icon={<i className="fa-solid fa-key mr-2" />}
          expanded={isKeysExpanded}
          onChange={() => setIsKeysExpanded(!isKeysExpanded)}
          style={{ backgroundColor: '#f0f0f0' }}
        >
          <PersonEditKeys person={person} isOpen={isKeysExpanded} />
        </Collapsible>

        {!isSignatory && (
          <>
            <Collapsible
              id="people-comments"
              title="Comments / Activity"
              icon={<i className="fa fa-comments-o mr-2" />}
              expanded={isCommentsExpanded}
              onChange={() => setIsCommentsExpanded(!isCommentsExpanded)}
              style={{ backgroundColor: '#f0f0f0' }}
            >
              <PersonEditComments selectedPerson={person} />
            </Collapsible>

            <Collapsible
              id="people-files"
              title="Files"
              icon={<i className="fa fa-files-o mr-2" />}
              expanded={isFilesExpanded}
              onChange={() => setIsFilesExpanded(!isFilesExpanded)}
              style={{ backgroundColor: '#f0f0f0' }}
            >
              <Card>
                <CardBody>
                  <PersonEditFiles selectedPerson={person} />
                </CardBody>
              </Card>
            </Collapsible>
          </>
        )}
        {isTrainingEnabled && (
          <Collapsible
            id="people-training-accomplishments"
            title="Training Accomplishments"
            icon={<i className="fa fa-trophy mr-2" />}
            expanded={isAccomplishmentsExpanded}
            onChange={() => setIsAccomplishmentsExpanded(!isAccomplishmentsExpanded)}
            style={{ backgroundColor: '#f0f0f0' }}
          >
            <Card>
              <CardBody>
                <PersonAccomplishments selectedPerson={person} isOpen={isAccomplishmentsExpanded} />
              </CardBody>
            </Card>
          </Collapsible>
        )}

        <Collapsible
          id="user-violations"
          title="Violations"
          icon={<i className="fa-solid fa-person-circle-exclamation" />}
          expanded={isViolationsExpanded}
          onChange={() => setIsViolationsExpanded(!isViolationsExpanded)}
          style={{ backgroundColor: '#f0f0f0' }}
        >
          <ViolationsLimitedListing person={person} isOpen={isViolationsExpanded} />
        </Collapsible>

        <Collapsible
          id="people-roles"
          title="Roles"
          icon={<i className="fa-solid fa-users mr-2" />}
          expanded={isRolesExpanded}
          onChange={() => setIsRolesExpanded(!isRolesExpanded)}
          style={{ backgroundColor: '#f0f0f0' }}
        >
          <PersonModalContext.Provider value={{ reloadData: loadData }}>
            <PersonDetailsRolesList selectedPerson={person} activeProfiles={roles} />
          </PersonModalContext.Provider>
        </Collapsible>

        <PeopleEditSimpleFieldModal
          isOpen={isSimpleEditModalOpen}
          onClose={onSimpleEditModalClose}
          onSave={onSimpleEditModalSave}
          config={editConfig}
        />

        <PersonEditTscAssociationModal
          isOpen={isTscEditModalOpen}
          onClose={onTscEditModalClose}
          onSave={onTscEditModalSave}
        />

        <Feature id="messages">
          <MessagesCreateModal
            isOpen={isMessageModalOpen}
            onClose={onMessageModalClose}
            state={{ user: app.user }}
            api={app.api}
            defaultRecipient={person}
          />
        </Feature>

        <PersonEditEnrollMobileModal
          isOpen={isEnrollModalOpen}
          onClose={onEnrollClose}
          onSave={onEnrollSave}
          fullName={fullName}
        />

        <PersonManageTrainingModal
          isOpen={isManageTrainingModalOpen}
          onClose={onManageTrainingClose}
          person={person}
          airport={app.airport}
        />

        <PeopleEditTscExemptionModal
          isOpen={isExemptModalOpen}
          onClose={onExemptClose}
          onSave={onExemptSave}
          userUuid={person.uuid}
          fullName={fullName}
          isCurrentlyExempt={person.is_tsc_exempt}
        />

        <UpdateFullNameModal
          isOpen={fNameModalOpen}
          fullName={fullName}
          setFullName={setFullName}
          person={person}
          setPerson={setPerson}
          onClose={() => {
            setFNameModalOpen(false);
          }}
        />

        <UpdateDateOfBirthModal
          isOpen={dobModalOpen}
          person={person}
          setPerson={setPerson}
          onClose={() => {
            setDobModalOpen(false);
          }}
          setDateOfBirth={setDateOfBirth}
        />

        <MergeDuplicateUsersModal
          isOpen={isDuplicatesModalOpen}
          onClose={onDuplicatesModalClosed}
          duplicates={duplicates}
          onMergeActionLabel="bypass background check"
          sourcePerson={selectedPerson}
        />

        <AdminPullFullTscInfo
          isOpen={isAdminPullFullTscInfoOpen}
          userUuid={person.uuid}
          airportPersonId={person.tsc_person_id}
          onClose={() => setIsAdminPullFullTscInfoOpen(false)}
        />

        <BadgeApplicationTrainingConfirmation
          isOpen={isBadgeApplicationTrainingConfirmationOpen}
          onClose={() => setIsBadgeApplicationTrainingConfirmationOpen(false)}
          onSave={() => setIsManageTrainingModalOpen(true)}
          fullName={fullName}
        />

        <FullUserActivity
          user={{ uuid: person.uuid, fullName, airportPersonId: person.tsc_person_id }}
          isOpen={isFullUserActivityOpen}
          onClose={() => setIsFullUserActivityOpen(false)}
        />

        <PersonLoginHistoryModal
          user={{ uuid: person.uuid, fullName, airportPersonId: person.tsc_person_id }}
          isOpen={isPersonLoginHistoryModalOpen}
          onClose={() => setIsPersonLoginHistoryModalOpen(false)}
        />

        <WebcamCaptureModal
          isOpen={isWebcamCaptureModalOpen}
          onClose={onWebcamCaptureModalClose}
          isCaptureOnlyMode={true}
          saveButtonLabel="Capture Image"
          title="Capture Headshot Image"
        />
      </ModalBody>
      <ModalFooter>
        <ModalActions closeLabel="Close" onClose={onModalClose} />
      </ModalFooter>
    </Modal>
  );
}
