// noinspection JSAnnotator

import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GoogleLogin } from '@react-oauth/google';
import { AppContext, appSettings, colors } from '../../../App';
import logo from '../../../containers/DefaultLayout/airbadge-logo.png';
import login from '../../../ajax/Account/AccountLoginRequest';
import verifyTwoFactorAuthCode from '../../../ajax/Account/AccountVerifyTwoFactorAuthCodeRequest';
import Loading from '../../../containers/DefaultLayout/Loading';
import request from '../../../ajax/Request';
import Feature from '../../../shared/Feature';
import { handleError } from '../../../utils';
import packageInfo from '../../../../package.json';

let requestTimeout = null;
let abortController = null;

export default function Login() {
  const app = useContext(AppContext);
  const { t } = useTranslation('translation');
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [twoFactorAuth, setTwoFactorAuth] = useState(false);
  const [userUuid, setUserUuid] = useState('');
  const [code, setCode] = useState('');
  const [error, setError] = useState(null);
  const { airport, enrollment, isMaintenanceMode, user } = app;

  const onLogin = () => {
    if (requestTimeout) clearTimeout(requestTimeout);
    if (abortController) abortController.abort();

    if (!email || !password) return;

    setIsLoading(true);
    setError(null);

    requestTimeout = setTimeout(() => {
      abortController = new AbortController();
      login({ email, password, signal: abortController.signal })
        .then(response => {
          const { success, twoFactorAuth, code, userUuid, message } = response;
          if (!success) {
            setError(message);
            return;
          }
          if (twoFactorAuth) {
            setTwoFactorAuth(true);
            setCode(code);
            setUserUuid(userUuid);
          } else {
            setupUserData(response);
          }
        })
        .catch(error => {
          handleError({ error });
          setError('The credentials you entered are incorrect. Use the link below if you need to reset your password.');
        })
        .finally(() => setIsLoading(false));
    }, 500);
  };

  const onTwoFactorSubmit = () => {
    if (!code) return;

    setIsLoading(true);
    verifyTwoFactorAuthCode(userUuid, code)
      .then(response => {
        if (response.expired) {
          setError('The code has expired or is no longer valid');
        } else {
          setupUserData(response);
        }
      })
      .catch(error => {
        handleError({ error });
        setError('The code has expired or is no longer valid');
      })
      .finally(() => setIsLoading(false));
  };

  const setupUserData = response => {
    const user = {
      authToken: response.auth_token,
      uuid: response.uuid,
      isSuperAdmin: response.isSuperAdmin,
      isDemoUser: response.isDemoUser,
      firstName: response.first_name,
      middleName: response.middle_name,
      lastName: response.last_name,
      suffix: response.suffix,
      nickname: response.nickname,
      dob: response.dob,
      email: response.email,
      phone: response.phone,
      mobile: response.mobile,
      profiles: response.profiles,
      activeProfile: response.active_profile,
      managerForCompanies: response.manager_for_companies,
      numActiveAudits: response.numActiveAudits,
    };

    const role = response.profiles?.length ? response.profiles[0].role.name : '';

    app.api.updateState({
      user,
      role,
      enrollment: response.enrollment,
    });

    localStorage.setItem('AirportBadges.user', JSON.stringify(user));

    // TODO: Action Items = only show this modal if the User is a ASC or TA
    app.showActionItemsSummaryModal();
  };

  const goToLogin = () => {
    setTwoFactorAuth(false);
  };

  useEffect(() => {
    console.info(`AirBadge version ${packageInfo.version}`);

    return () => {
      if (requestTimeout) clearTimeout(requestTimeout);
      if (abortController) abortController.abort();
    };
  }, []);

  if (enrollment) return <Redirect to={'/enrollment'} />;
  if (user && user.authToken) return <Redirect to={'/default/home'} />;

  return (
    <div className="app flex-row align-items-center">
      <Loading loading={isLoading} />
      <Container>
        <Row className="justify-content-center">
          <Col>
            <div className="m-auto" style={{ maxWidth: '500px' }}>
              <div className="text-center py-4">
                <img src={logo} style={appSettings.bigLogoStyle} alt="AirBadge" />
                {isMaintenanceMode && (
                  <Card>
                    <CardBody>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div>
                          <i className="fa fa-exclamation-triangle text-warning" style={{ fontSize: 30 }} />
                        </div>
                        <div>
                          <strong
                            style={{
                              fontSize: 25,
                              opacity: 0.8,
                              letterSpacing: 1,
                            }}
                          >
                            NOTICE
                          </strong>
                        </div>
                        <div>
                          <i className="fa fa-exclamation-triangle text-warning" style={{ fontSize: 30 }} />
                        </div>
                      </div>
                      <div style={{ marginTop: 16, fontWeight: 500, textAlign: 'center' }}>
                        Logins have been temporarily disabled.
                      </div>
                    </CardBody>
                  </Card>
                )}

                <Card className="p-4">
                  <CardBody>
                    <div hidden={twoFactorAuth === true}>
                      <h1 className="text-center mb-2">{t('login.page.title')}</h1>
                      <div className="text-center text-muted" style={{ marginTop: 18, marginBottom: 32 }}>
                        {t('login.page.subtitle')}
                      </div>
                      <div className="text-left">
                        <FormGroup className="mb-4">
                          <Label>Email (or mobile # if registered)</Label>
                          <Input
                            autoFocus
                            type="text"
                            placeholder="Email"
                            autoComplete="Email"
                            value={email}
                            onChange={({ target }) => setEmail(target.value)}
                            onKeyDown={e => {
                              if (e.key === 'Enter') onLogin();
                            }}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>Password</Label>
                          <Input
                            type="password"
                            placeholder="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={({ target }) => setPassword(target.value)}
                            onKeyDown={e => {
                              if (e.key === 'Enter') onLogin();
                            }}
                          />
                        </FormGroup>
                      </div>
                      {error && <div style={{ color: colors.danger, marginTop: 16, textAlign: 'left' }}>{error}</div>}
                      <Row>
                        <Col>
                          <Button
                            id="sign_in_btn"
                            className="mt-4"
                            color="primary"
                            style={{ width: 120, marginTop: 15 }}
                            onClick={onLogin}
                          >
                            {t('login.page.submit')}
                          </Button>
                        </Col>
                      </Row>
                      <div
                        style={{
                          marginTop: 16,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Link to={'/new-user'} style={{ marginTop: 15 }}>
                          <Button color="link">{t('login.page.new-user')}</Button>
                        </Link>
                        <Link to={'/forgot-password'} style={{ marginTop: 15 }}>
                          <Button color="link">{t('login.page.reset-password')}</Button>
                        </Link>
                      </div>

                      <Feature id="byo_auth">
                        <div className="separator" style={{ marginTop: 16 }}>
                          OR
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 16 }}>
                          <GoogleLogin
                            onSuccess={({ credential }) => {
                              request('authenticated-user', 'POST', 'account/login-third-party', {
                                type: 'google',
                                token: credential,
                              })
                                .then(setupUserData)
                                .catch(error => handleError({ error }));
                            }}
                            onError={() => handleError({ message: 'Login failed' })}
                          />
                        </div>
                      </Feature>
                    </div>

                    <div hidden={twoFactorAuth === false}>
                      <h1 className="text-center">{t('login.page.title')}</h1>
                      <p className="text-muted text-left" style={{ marginTop: 18, marginBottom: 32 }}>
                        {t('login.page.auth-code-instructions')}
                      </p>
                      <div className="text-left">
                        <FormGroup className="mb-3">
                          <Label>Authentication Code</Label>
                          <Input
                            autoFocus
                            id="tfa_input"
                            type="password"
                            value={code}
                            placeholder="Code"
                            autoComplete="one-time-code"
                            onChange={({ target }) => setCode(target.value)}
                            onKeyDown={e => {
                              if (e.key === 'Enter') onTwoFactorSubmit();
                            }}
                          />
                        </FormGroup>
                      </div>
                      {error && <div style={{ color: colors.danger, marginTop: 16, textAlign: 'left' }}>{error}</div>}
                      <Row>
                        <Col
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <Button
                            id="tfa_btn"
                            color="primary"
                            style={{ width: 120, marginTop: 15 }}
                            onClick={onTwoFactorSubmit}
                          >
                            {t('login.page.submit')}
                          </Button>
                          <button style={{ marginTop: 15 }} type="button" className="btn btn-link" onClick={goToLogin}>
                            {t('login.page.auth-code-cancel')}
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>

                <div className="text-center">
                  <p>{t('login.page.provided-by')}</p>
                  <p>{airport && airport.name}</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
