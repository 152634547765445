import React, { useEffect, useState } from 'react';
import { Alert, Col, FormFeedback, FormGroup, Input, Label, List, ListInlineItem, Row } from 'reactstrap';
import settingsApi from '../../../../ajax/Settings/settingsApi';
import { handleError, notify } from '../../../../utils';
import AirBadgeModal, { MODAL_SIZE_MEDIUM } from '../../../../shared/components/AirBadgeModal/AirBadgeModal';

export function Requirement({ isGood, text }) {
  let className = 'fa-regular fa-circle mr-2';
  if (isGood) {
    className = 'fa-regular fa-circle-check mr-2 text-success';
  }

  return (
    <ListInlineItem style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 8 }}>
      <i className={className} style={{ fontSize: 18 }}></i>
      <div>{text}</div>
    </ListInlineItem>
  );
}

export default function ChangePasswordModal({ isOpen, user, onClose, api, history }) {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPassword2, setNewPassword2] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isEightCharactersLong, setIsEightCharactersLong] = useState(false);
  const [hasOneLowercaseCharacter, setHasOneLowercaseCharacter] = useState(false);
  const [hasOneUppercaseCharacter, setHasOneUppercaseCharacter] = useState(false);
  const [hasOneNumber, setHasOneNumber] = useState(false);

  useEffect(() => {
    if (!isOpen) return;
    setCurrentPassword('');
    setNewPassword('');
    setNewPassword2('');
  }, [isOpen]);

  const isMatchingCurrentPassword = () => {
    if (!currentPassword || !newPassword) return false;
    return currentPassword === newPassword;
  };

  const isNewPasswordsMatching = () => {
    if (!newPassword || !newPassword2) return false;
    return newPassword !== newPassword2;
  };

  const isSaveButtonDisabled = () => {
    if (!newPassword || !currentPassword || !newPassword2) return true;
    if (currentPassword === newPassword) return true;
    if (newPassword !== newPassword2) return true;
    if (!isEightCharactersLong) return true;
    if (!hasOneLowercaseCharacter) return true;
    if (!hasOneUppercaseCharacter) return true;
    if (!hasOneNumber) return true;
    return isSaving;
  };

  const onSave = () => {
    setIsSaving(true);
    api.toggleLoading(true);

    settingsApi
      .changePassword({
        user,
        newPassword,
        oldPassword: currentPassword,
      })
      .then(({ success, errors }) => {
        if (!success) {
          handleError({ message: errors.join('; ') });
          return;
        }

        notify({ message: 'Password successfully changed' });
        onClose();
        history.push('/logout');
      })
      .catch(error => handleError({ error }))
      .finally(() => {
        api.toggleLoading(false);
        setIsSaving(false);
      });
  };

  const evaluatePasswordStrength = () => {
    setIsEightCharactersLong(newPassword.length >= 8);
    setHasOneLowercaseCharacter(newPassword.match(/[a-z]/));
    setHasOneUppercaseCharacter(newPassword.match(/[A-Z]/));
    setHasOneNumber(newPassword.match(/[0-9]/));
  };

  useEffect(() => {
    evaluatePasswordStrength(newPassword);
  }, [newPassword]);

  return (
    <AirBadgeModal
      size={MODAL_SIZE_MEDIUM}
      title="Change Password"
      isOpen={isOpen}
      onClose={onClose}
      saveLabel="Change Password"
      onSave={onSave}
      saveDisabled={isSaveButtonDisabled()}
    >
      <Alert color="info">
        <p>
          After your password is successfully changed, you will be logged out and will need to log back in using your
          new password.
        </p>
        <p>Your new password cannot match your current password and you may not reuse the last 3 passwords.</p>
      </Alert>
      <Row>
        <Col>
          <FormGroup>
            <Label>Current Password</Label>
            <Input
              type="password"
              value={currentPassword}
              onChange={event => setCurrentPassword(event.target.value)}
              required
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label>New Password</Label>
            <Input
              type="password"
              value={newPassword}
              onChange={event => setNewPassword(event.target.value)}
              required
              invalid={isMatchingCurrentPassword()}
            />
            <FormFeedback>New password must be different than your current password</FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label>Re-enter New Password</Label>
            <Input
              type="password"
              value={newPassword2}
              onChange={event => setNewPassword2(event.target.value)}
              required
              invalid={isNewPasswordsMatching()}
            />
            <FormFeedback>Passwords must match</FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      <strong>Password Requirements</strong>
      <List style={{ marginTop: 8 }}>
        <Requirement isGood={isEightCharactersLong} text="At least 8 characters long" />
        <Requirement isGood={hasOneLowercaseCharacter} text="Contains at least 1 lower case character" />
        <Requirement isGood={hasOneUppercaseCharacter} text="Contains at least 1 upper case character" />
        <Requirement isGood={hasOneNumber} text="Contains at least 1 number" />
      </List>
    </AirBadgeModal>
  );
}
