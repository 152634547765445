import React, { useContext, useEffect, useState } from 'react';
import { Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import { AppContext } from '../../../../../App';
import CustomModalHeader from '../../../../../shared/components/CustomModalHeader/CustomModalHeader';
import ModalActions from '../../../../../shared/components/ModalActions/ModalActions';
import peopleApi from '../../../../../ajax/People/peopleApi';
import { handleError } from '../../../../../utils';

export default function PeopleEditTscExemptionModal({
  isOpen,
  userUuid,
  fullName,
  isCurrentlyExempt,
  onClose,
  onSave,
}) {
  const app = useContext(AppContext);
  const [comment, setComment] = useState('');

  const onSaveChanges = () => {
    const { api } = app;
    api.toggleLoading(true);
    peopleApi
      .changeTscExemption({ userUuid, isTscExempt: !isCurrentlyExempt, comment })
      .then(({ success }) => {
        if (success) {
          onSave();
        } else {
          handleError({ message: 'Unable to change exemption status' });
        }
      })
      .catch(error => handleError({ error, message: 'Unable to change exemption status' }))
      .finally(() => api.toggleLoading(false));
  };

  useEffect(() => {
    setComment('');
  }, [isOpen]);

  if (!isOpen) return null;

  // This is commonly used for LEOs and other individuals not reported to TSC.

  return (
    <Modal size="md" isOpen={isOpen} toggle={onClose}>
      <CustomModalHeader toggle={onClose}>
        {isCurrentlyExempt ? 'Remove STA Exemption' : 'Mark STA Exempt'}
      </CustomModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <p>WARNING!</p>
            <p>
              This will {isCurrentlyExempt ? 'enable' : 'disable'} TSC updates for this person and their badges.{' '}
              {isCurrentlyExempt ? null : 'This is commonly used for LEOs and other individuals not reported to TSC.'}
            </p>
            {isCurrentlyExempt ? (
              <p>
                Are you sure you want to allow TSC updates for <strong>{fullName}</strong>? This will also re-establish
                the STA requirement for badge applications.
              </p>
            ) : (
              <p>
                Are you sure you want to exempt <strong>{fullName}</strong> from the STA requirement and TSC updates?
              </p>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label>
                Comment <i style={{ fontSize: 'smaller' }}>(optional)</i>
              </Label>
              <Input type="textarea" value={comment} onChange={({ target }) => setComment(target.value)} />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <ModalActions
          closeLabel="Cancel"
          onClose={onClose}
          saveLabel={isCurrentlyExempt ? 'Enable Updates' : 'Disable Updates'}
          saveColor="warning"
          onSave={onSaveChanges}
        />
      </ModalFooter>
    </Modal>
  );
}
