import React, { useState } from 'react';
import { Button, Card, CardBody, Col, Container, Input, InputGroup, InputGroupText, Row } from 'reactstrap';
import Loading from '../../../containers/DefaultLayout/Loading';
import Message from '../../../containers/DefaultLayout/Message';
import recoverPassword from '../../../ajax/Account/AccountRecoverPasswordRequest';
import logo from '../../../containers/DefaultLayout/airbadge-logo.png';
import { appSettings, colors } from '../../../App';
import { handleError } from '../../../utils';

export default function ForgotPassword({ api, state, history }) {
  const [email, setEmail] = useState('');
  const [isDone, setIsDone] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { airport } = state;
  const goToLogin = () => history.replace('/login');

  const onRecoverClick = async () => {
    if (!email) return;

    try {
      setErrorMessage(null);
      api.toggleLoading(true);
      const { success, message } = await recoverPassword(email);
      if (!success) {
        setErrorMessage(message);
      } else {
        setErrorMessage(null);
        setIsDone(true);
      }
    } catch (error) {
      handleError({ error });
    } finally {
      api.toggleLoading(false);
    }
  };

  return (
    <div className="app flex-row align-items-center">
      <Loading loading={state.loading} />
      <Message message={state.messageContent} brand={state.messageBrand} />
      <Container>
        <Row className="justify-content-center">
          <Col sm="12" md="10" lg="6" xl="6">
            <div className="text-center py-4">
              <img src={logo} style={appSettings.bigLogoStyle} alt="AirBadge" />
            </div>
            <Card>
              <CardBody>
                {isDone && (
                  <div
                    style={{
                      textAlign: 'center',
                      margin: '20px 0',
                      fontSize: 'larger',
                    }}
                  >
                    <h2 className="mb-3">Request Received</h2>
                    <p>
                      If <strong>{email}</strong> is in AirBadge, an email has been sent to that address. The email
                      contains a password reset link that expires in 10 minutes. Check your email now.
                    </p>
                    <Button onClick={goToLogin} style={{ marginTop: 10 }}>
                      Return to Login
                    </Button>
                  </div>
                )}
                {!isDone && (
                  <>
                    <div className="mt-2 mb-3" style={{ textAlign: 'center' }}>
                      <h1>Password Assistance</h1>
                    </div>
                    <p className="text-muted mb-4">
                      Enter the email address associated with your account to reset your password
                    </p>
                    <InputGroup className="mb-4">
                      <InputGroupText>
                        <i className="fa fa-envelope-o" />
                      </InputGroupText>
                      <Input
                        autoFocus
                        type="text"
                        placeholder="Email"
                        autoComplete="Email"
                        onChange={({ target }) => setEmail((target.value || '').trim())}
                        onKeyDown={e => {
                          if (e.key === 'Enter') return onRecoverClick();
                        }}
                      />
                    </InputGroup>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      {!!errorMessage && <div style={{ color: colors.danger, marginBottom: 24 }}>{errorMessage}</div>}
                      <Button disabled={!email} color="primary" onClick={onRecoverClick}>
                        Request Password Reset
                      </Button>
                      <button type="button" className="btn btn-link" onClick={goToLogin} style={{ marginTop: 15 }}>
                        Return to Login
                      </button>
                    </div>
                  </>
                )}
              </CardBody>
            </Card>
            <div className="text-center mt-4">
              <p>AirBadge is Provided by</p>
              <p>{airport && airport.name}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
