import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ActionPanelContext } from '../../../BadgeApplicationActionPanel';
import request from '../../../../../../../../ajax/Request';
import { handleError } from '../../../../../../../../utils';

let requestTimeout = null;
let abortController = null;

export default function ValidateTrainingAppointment() {
  const { badgeApplication, updateBadgeApplicationConfig } = useContext(ActionPanelContext);
  const [isLoading, setIsLoading] = useState(false);
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [selectedAppointmentType, setSelectedAppointmentType] = useState(null);

  const onAppointmentTypeSelected = option => {
    setSelectedAppointmentType(option);
    badgeApplication.config.trainingAppointment.appointmentTypeUuid = option.value;
    updateBadgeApplicationConfig({ updatedConfig: { ...badgeApplication.config } });
  };

  const loadAppointmentTypes = () => {
    if (requestTimeout) clearTimeout(requestTimeout);
    if (abortController) abortController.abort();

    setIsLoading(true);
    requestTimeout = setTimeout(() => {
      abortController = new AbortController();
      request('authenticated-user', 'GET', 'bookings/available-appointment-types', null, abortController.signal)
        .then(results => {
          const types = results.map(r => ({ label: r.name, value: r.uuid, original: r }));
          setAppointmentTypes(types);
          if (badgeApplication?.config?.trainingAppointment?.appointmentTypeUuid) {
            const currentType = types.find(
              t => t.value === badgeApplication.config.trainingAppointment.appointmentTypeUuid
            );
            setSelectedAppointmentType(currentType);
          }
        })
        .catch(error => handleError({ error }))
        .finally(() => setIsLoading(false));
    }, 500);
  };

  useEffect(() => {
    setIsLoading(false);
    setAppointmentTypes([]);
    setSelectedAppointmentType(null);
    loadAppointmentTypes();

    return () => {
      if (requestTimeout) clearTimeout(requestTimeout);
      if (abortController) abortController.abort();
    };
  }, []);

  return (
    <div>
      <Grid container>
        <Grid item>
          <FormGroup>
            <Label>
              <div>Training Appointment Type</div>
              <i style={{ fontSize: 'smaller' }}>
                Select the appointment type that the badge holder will need to schedule
              </i>
            </Label>
            {isLoading ? (
              <div>
                <CircularProgress size={15} style={{ marginRight: 8 }} />
                <i style={{ opacity: 0.7 }}>Loading appointment types...</i>
              </div>
            ) : (
              <Select
                placeholder=""
                classNamePrefix="airbadge"
                className="form-select"
                options={appointmentTypes}
                value={selectedAppointmentType}
                onChange={onAppointmentTypeSelected}
              />
            )}
          </FormGroup>
        </Grid>
      </Grid>
    </div>
  );
}
