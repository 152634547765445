import React, { useContext, useEffect, useState } from 'react';
import { Button, FormGroup, Label, Table } from 'reactstrap';
import Select from 'react-select';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';
import AirBadgeModal, { MODAL_SIZE_MEDIUM } from '../../../../../shared/components/AirBadgeModal/AirBadgeModal';
import trainingApi from '../../../../../ajax/Training/trainingApi';
import { getLMSLabel, handleError } from '../../../../../utils';
import { AppContext, learningManagementSystems } from '../../../../../App';
import { getDrivingOptions } from '../../../../../shared/BadgeTypes/drivingOptionsUtils';

let requestTimeout = null;
let abortController = null;

export default function BadgeApplicationTrainingEditor({
  badgeType,
  config,
  onTrainingConfigChanged,
  showAdminMode,
  isDisabled,
}) {
  const { airport } = useContext(AppContext);
  const { isIETEnabled, isDigicastEnabled, isSSIEnabled, isAirBadgeLMSEnabled } = airport;
  let totalConnectedLMS = 0;
  if (isIETEnabled) totalConnectedLMS += 1;
  if (isDigicastEnabled) totalConnectedLMS += 1;
  if (isSSIEnabled) totalConnectedLMS += 1;
  if (isAirBadgeLMSEnabled) totalConnectedLMS += 1;

  const [rows, setRows] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isLoadingAvailableTraining, setIsLoadingAvailableTraining] = useState(false);
  const [availableTraining, setAvailableTraining] = useState([]);
  const [assignedTraining, setAssignedTraining] = useState([]);
  const [selectedTraining, setSelectedTraining] = useState(null);
  const [endorsementOptions, setEndorsementOptions] = useState([]);
  const [selectedEndorsements, setSelectedEndorsements] = useState([]);

  const buildEndorsementOptions = () => {
    let options = getDrivingOptions(badgeType?.config?.endorsements?.driving) || [];
    options = options.filter(o => !!o.isEnabled && o.value !== 'none');
    options = options.map(o => ({ label: o.displayName, value: `driving_${o.value}` }));
    setEndorsementOptions([...options, { label: 'Escort', value: 'escort' }, { label: 'LEO', value: 'leo' }]);
  };

  const onRemoveTrainingClicked = ({ system, type, code }) => {
    const newEntries = [];
    config.forEach(x => {
      if (!(x.system === system && x.type === type && x.code === code)) {
        newEntries.push(x);
      }
    });
    onTrainingConfigChanged({ updatedConfig: cloneDeep(newEntries) });
  };

  const onAddTraining = () => {
    config.push({ ...selectedTraining.original, endorsements: selectedEndorsements.map(x => x.value) });
    onAddModalClose();
    onTrainingConfigChanged({ updatedConfig: cloneDeep(config) });
  };

  const onAddModalClose = () => {
    if (requestTimeout) {
      if (abortController) abortController.abort();
      clearTimeout(requestTimeout);
    }

    setSelectedEndorsements([]);
    setSelectedTraining(null);
    setIsAddModalOpen(false);
  };

  const onAddClicked = () => {
    getAllAvailableTraining();
    setIsAddModalOpen(true);
  };

  const getAllAvailableTraining = () => {
    if (requestTimeout) {
      if (abortController) abortController.abort();
      clearTimeout(requestTimeout);
    }

    setAvailableTraining([]);
    setIsLoadingAvailableTraining(true);

    requestTimeout = setTimeout(() => {
      abortController = new AbortController();
      setIsLoadingAvailableTraining(true);
      trainingApi
        .getAllAvailableTraining({ signal: abortController.signal })
        .then(({ availableTraining }) => {
          let entries = [];
          availableTraining.forEach(({ system, groups = [], courses = [] }) => {
            // Squish all the data together into one array
            const systemValue = learningManagementSystems.find(x => x.value === system).value;
            entries = [
              ...entries,
              ...groups.map(g => ({
                system: systemValue,
                type: 'group',
                ...g,
              })),
              ...courses.map(c => ({ system: systemValue, type: 'course', ...c })),
            ];
          });

          // Remove entries that are already assigned
          entries = entries.filter(entry => {
            const { system, type, code } = entry;
            return !assignedTraining.find(x => x.system === system && x.type === type && x.code === code);
          });

          // Convert to objects usable by the dropdown select component
          entries = entries.map(entry => {
            const { system, type, code, name } = entry;
            let label = `${type.charAt(0).toUpperCase() + type.slice(1)} - ${name}`;
            if (totalConnectedLMS > 1) {
              const systemLabel = learningManagementSystems.find(x => x.value === system).label;
              label = `${systemLabel} - ${label}`;
            }
            return { label, value: code, original: entry };
          });

          setAvailableTraining(entries);
        })
        .catch(error => handleError({ error }))
        .finally(() => setIsLoadingAvailableTraining(false));
    }, 500);
  };

  const buildRows = () => {
    const entries = sortBy(cloneDeep(config), ['system', 'type', 'name']);
    setAssignedTraining(entries);

    if (entries.length === 0) {
      setRows([
        <tr key="empty">
          <td colSpan={5} className="text-center">
            <i>No training selected</i>
          </td>
        </tr>,
      ]);
    } else {
      setRows(
        entries.map(row => {
          const { system, type, code, name, endorsements } = row;
          return (
            <tr key={`${system}-${type}-${code}`}>
              {totalConnectedLMS > 1 ? <td>{getLMSLabel(system)}</td> : null}
              <td style={{ textTransform: 'capitalize' }}>{type}</td>
              <td>{name}</td>
              {showAdminMode && <td>{endorsements.map(e => (e || '').replace('driving_', '')).join(', ') || '-'}</td>}
              <td>
                {isDisabled ? (
                  <span>&nbsp;</span>
                ) : (
                  <button className="btn btn-link p-0 text-danger" onClick={() => onRemoveTrainingClicked(row)}>
                    <i className="fa-regular fa-trash-can mr-2"></i>Remove
                  </button>
                )}
              </td>
            </tr>
          );
        })
      );
    }
  };

  useEffect(() => {
    if (config) {
      buildEndorsementOptions();
      buildRows();
    }

    return () => {
      if (requestTimeout) {
        if (abortController) abortController.abort();
        clearTimeout(requestTimeout);
      }
    };
  }, [config]);

  if (!config) return null;

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ flex: 0.25 }}>
          <Button size="sm" color="primary" onClick={onAddClicked} className="mb-3" disabled={isDisabled}>
            <i className="fa-solid fa-plus mr-2"></i>Add Training
          </Button>
        </div>
        <div style={{ flex: 0.5 }}>Add training to be automatically assigned after vetting has completed</div>
        <div style={{ flex: 0.25 }}>&nbsp;</div>
      </div>
      <Table striped>
        <thead>
          <tr>
            {totalConnectedLMS > 1 ? <th style={{ width: 120 }}>System</th> : null}
            <th style={{ width: 70 }}>Type</th>
            <th>Name</th>
            {showAdminMode && <th>Endorsements</th>}
            <th style={{ width: 110 }}>&nbsp;</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>

      <AirBadgeModal
        size={MODAL_SIZE_MEDIUM}
        title="Add Training"
        isOpen={isAddModalOpen}
        onClose={onAddModalClose}
        onSave={onAddTraining}
        saveLabel="Add Training"
        saveDisabled={!selectedTraining}
      >
        <FormGroup>
          <Label>Available Training</Label>
          <Select
            placeholder=""
            classNamePrefix="airbadge"
            className="form-select"
            noOptionsMessage={() => 'No training found'}
            isLoading={isLoadingAvailableTraining}
            options={availableTraining}
            value={selectedTraining}
            onChange={value => setSelectedTraining(value)}
          />
        </FormGroup>
        {showAdminMode && (
          <FormGroup className="mt-4">
            <Label>Is this for one or more endorsements?</Label>
            <Select
              isMulti
              placeholder=""
              classNamePrefix="airbadge"
              className="form-select"
              options={endorsementOptions}
              value={selectedEndorsements}
              onChange={value => setSelectedEndorsements(value)}
            />
          </FormGroup>
        )}
      </AirBadgeModal>
    </div>
  );
}
